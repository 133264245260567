import React, { useRef, useEffect, useState } from 'react'
import styled from "styled-components";
import * as faceapi from 'face-api.js';
import { useDispatch } from 'react-redux';

// component
import Button from "../../../Control/Button";

// image
import ic_close_black from "../../../../images/ic_close_black.svg"
import ice_error from "../../../../images/ice_error.svg";

// action
import { closePopup } from "../../../../redux/actions/popupAction/popupContainerAction"

// tool
import { refixCaptureDimension } from "../../../../helper/tools";

const MODEL_URL = '/models'

const ButtonCaptureStyle = styled(Button)`
    margin: 38px auto 0;
    width: 272px;
`;

const VIDEO_DIMENSION = {
    WIDTH: 480,
    HEIGHT: 640
}

const CAPTURE_DIMENSION = {
    WIDTH: 320,
    HEIGHT: 480
}

const Bound = styled.div`
    position: relative;
    transition: height .2s ease-in-out;
   

    .error-camera {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #EA3939;
        margin: 0 64px 10px;
        height: 44px;
        border-radius: 5px;
        /* transition: transform .3s ease-in-out; */
        animation-name: errors;
        animation-duration: 1s;
        position: absolute;
        top: -54px;


        & > img {
            margin-left: 19px;
            cursor: pointer;
        }

        span {
            margin-left: 10px;
            color: #FFFFFF;
        }

        button {
            outline: none;
            background: #FFFFFF;
            border: none;
            padding: 5px 10px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            color: #EA3939;
            margin-left: 20px;
            border-radius: 3px;
            margin-right: 12px;
            cursor: pointer;
        }
    }

    @keyframes errors {
        from {
            transform: translate(0, -20vh);
        }
        to {
            transform: translate(0, 0);
        }
    }

    .face-recognize-area {
        position: relative;
    }

    canvas {
        position: absolute;
        top: 0;
        left: 0;
    }

    .black-screen {
        position: absolute;
        top: 0;
        left: 0;
        width: ${VIDEO_DIMENSION.WIDTH + "px"};
        height: ${VIDEO_DIMENSION.HEIGHT + "px"};
        background: #000000;
    }

    .close-popup {
        position: absolute;
        top: 0;
        right: -60%;
        cursor: pointer;
    }
`;

const TIME_OUT_CAMERA = 30000; //30 seconds

const CaptureVideo = ({setStep, lstStep, imgCapture, setImgCapture}) => {

    const [isLoadedVideo, setIsLoadedVideo] = useState(false);
    // const [stream, setStream] = useState(null);
    const [numOfFace, setNumOfFace] = useState(0);
    const [faceImages, setFaceImages] = useState([])
    const [isMounted, setIsMounted] = useState(false);
    const [errorCamera, setErrorCamera] = useState(false);
    const [isRenderErrorCamera, setIsRenderErrorCamera] = useState(true);
    // const [timeOutCamera, setTimeOutCamera] = useState(null);
    const captureVideo = useRef(null);
    const wrapFaceRecognize = useRef(null);
    const intervalTimeOutCamera = useRef(null);
    const streamRef = useRef(null);
    const errorCameraRef = useRef(null);
    const dispatch = useDispatch();
    
    
    useEffect(() => {
        setIsMounted(true);
    }, [])

    useEffect(() => {
        if(!isMounted) return;

        // let intervalTimeOutCamera = null;
        
        Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
            // faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
            faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
            // faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL)
        ]).then(_startVideo)
        
        const video = captureVideo.current;
        // const canvas = canvasFace.current;
        const wrapRecognize = wrapFaceRecognize.current;
        let interval = null;

        const handleEventPlayVideo = () => {
            const canvas = faceapi.createCanvasFromMedia(video);
            wrapRecognize.append(canvas)
            const displaySize = {width: video.width, height: video.height};


            interval = setInterval(async () => {
                const detections = await faceapi
                    .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions({ inputSize: 128, scoreThreshold: 0.65 }))
                // console.log(detections)

                setNumOfFace(detections.length)
                
                canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
                const resizeDetections = faceapi.resizeResults(detections, displaySize)

                faceapi.drawDetection(canvas, resizeDetections, { withScore: process.env.NODE_ENV ==="production"?false:true })

                if(detections[0]){
                    const item = detections[0];

                    const itemFormatToParam = {
                        posX: item.box.x,
                        posY: item.box.y, 
                        widthRecog: item.box.width, 
                        heightRecog: item.box.height
                    }

                    const {newX, newY} = refixCaptureDimension(
                        itemFormatToParam,
                        CAPTURE_DIMENSION.WIDTH,
                        CAPTURE_DIMENSION.HEIGHT,
                        VIDEO_DIMENSION.WIDTH,
                        VIDEO_DIMENSION.HEIGHT
                    )

                    // debugger

                    const rect = new faceapi.Rect(newX, newY, CAPTURE_DIMENSION.WIDTH, CAPTURE_DIMENSION.HEIGHT)
                    // debugger
                    const faceImages = await faceapi.extractFaces(video, [rect])
                    setFaceImages(faceImages)
                }
                
            })
        }

        video.addEventListener('play', handleEventPlayVideo)

        return () => {
            clearInterval(interval)
            clearTimeout(intervalTimeOutCamera.current)
            video.src=""
            video.removeEventListener('play', handleEventPlayVideo)
            _stopWebcam()
        }
    }, [isMounted])

    const _startVideo = async () => {
        try {
            const streamUrl = await navigator.mediaDevices.getUserMedia({ video: {width: VIDEO_DIMENSION.WIDTH, height: VIDEO_DIMENSION.HEIGHT} });
            if(!captureVideo.current){
                streamUrl && streamUrl.getTracks().forEach((track) => track.stop())
                return;
            }
            // setStream(streamUrl)
            captureVideo.current.srcObject = streamUrl;
            streamRef.current = streamUrl
            setIsLoadedVideo(true)
            intervalTimeOutCamera.current = setTimeout(() => {
                _stopWebcam()
                // setIsTimeOutCamera(true)
                setStep(lstStep.TIMEOUT)
                return;
            }, TIME_OUT_CAMERA)

            // setTimeOutCamera(intervalTimeOutCamera)
        }
        catch(err){
            console.log(err)
            setErrorCamera(true);
        }
    }


    const _handleCapture = () => {
        // clearTimeout(timeOutCamera);
        if(errorCamera){
            setIsRenderErrorCamera(true);
            return;
        }

        if(numOfFace === 0){
            _stopWebcam()
            setStep(lstStep.ERROR_CAPTURE_NO_FACE)
            return;
        }

        if(numOfFace > 1){
            _stopWebcam()
            setStep(lstStep.ERROR_CAPTURE_MORE_FACE)
            return;
        }

        // const canvas = document.createElement('canvas');

        // canvas.width = VIDEO_DIMENSION.WIDTH;
        // canvas.height = VIDEO_DIMENSION.HEIGHT;

        // const video = captureVideo.current;
        // canvas.getContext('2d').drawImage(video, 0, 0, VIDEO_DIMENSION.WIDTH, VIDEO_DIMENSION.HEIGHT);

        // const img = canvas.toDataURL("image/png");

        const img = faceImages[0].toDataURL("image/png");
        
        _stopWebcam()
        setImgCapture(img)
        setStep(lstStep.CONFIRM_CAPTURE)
    }

    const _handleClosePopup = () => {
        _stopWebcam()
        dispatch(closePopup())
    }

    const _stopWebcam = async () => {
        // console.log("ping")

        const video = captureVideo.current;
        if(video){
            video.src=""
        }
        setTimeout(() => {
            // console.log(stream)
            // console.log(streamRef)
            // stream && stream.getTracks().forEach((track) => track.stop())
            streamRef.current && streamRef.current.getTracks().forEach((track) => track.stop())
        }, 0)
        
    }

    const _handleRetry = () => {
        setTimeout(() => {
            setErrorCamera(false);
            _startVideo()
        }, 500)
    }


    return (
        <Bound>
            {
                errorCamera && isRenderErrorCamera &&
                <div className="error-camera" ref={errorCameraRef}>
                    <img 
                        src={ice_error} 
                        alt="error"
                        onClick={() => setIsRenderErrorCamera(false)}
                    />
                    <span>Could not find your camera</span>
                    <button onClick={_handleRetry}>Retry</button>
                </div>
            }
            <div 
                className="face-recognize-area"
                ref={wrapFaceRecognize}
            >
                <video 
                    id="inputVideo" 
                    ref={captureVideo}
                    width={480}
                    height={640}
                    autoPlay 
                    muted 
                    playsInline
                />
                {
                    !isLoadedVideo &&
                    <div className="black-screen"></div>
                }
                {/* <canvas id="overlay" ref={canvasFace} /> */}
                {/* <img
                    src=""
                    id="test"
                /> */}
            </div>
            <img 
                src={ic_close_black} 
                alt="close-popup" 
                className="close-popup"
                onClick={_handleClosePopup}
            />
            <ButtonCaptureStyle 
                type="PRIMARY"
                text="CAPTURE"
                onClick={_handleCapture}
            /> 
        </Bound>
    )
}

export default CaptureVideo
