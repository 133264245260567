import React, { Suspense, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Redirect } from "react-router";
import { Switch, Route } from "react-router-dom";
//host
import { hostSocket } from "../../host/host";
//constant
import * as keys from "../../constant/key";
//component
import MenuBar from "./Menu/MenuBar";
import { setSocket } from "../../redux/actions/socketAction/socketAction";
import { getNavigationData } from "../../helper/tools";
import { switchRouter } from "../../redux/actions/routerAction/routerAction";

//AcountManagement
const ChangePassword = React.lazy(() =>
    import("./AccountManage/ChangePassword/ChangePassword")
);
const Support = React.lazy(() => import("./AccountManage/Support/Support"));
//Device Setting
const MainLocation = React.lazy(() =>
    import("./DeviceSetting/Locations/MainLocation")
);
const DetailLocation = React.lazy(() =>
    import("./DeviceSetting/Locations/DetailLocation")
);
const Devices = React.lazy(() => import("./DeviceSetting/Devices"));
//Visitor
const VisitorList = React.lazy(() => import("./Visitors/List"));
const VisitorNew = React.lazy(() => import("./Visitors/New"));
const VisitorDetail = React.lazy(() => import("./Visitors/Detail"));
const VisitorEdit = React.lazy(() => import("./Visitors/Edit"));
//Employee
const EmployeeList = React.lazy(() => import("./Employee/List"));
const EmployeeEmptyPage = React.lazy(() => import("./EmployeeEmptyPage"));
const EmployeeNew = React.lazy(() => import("./Employee/New"));
const EmployeeDetail = React.lazy(() => import("./Employee/Detail"));
const EmployeeEdit = React.lazy(() => import("./Employee/Edit"));
//Attendance
const AttendanceList = React.lazy(() => import("./Attendance/List"));
const AttendanceEmptyPage = React.lazy(() => import("./AttendanceEmptyPage"));

//Activity
const ActivityList = React.lazy(() => import("./ACM/List"));

//img
//ic
//data
//action
//component
//styled
const Bound = styled.div`
    display: flex;
    flex: 1;
    .main-page-container {
        padding-top: 60px;
        flex: 1;
        overflow: hidden;
        &::-webkit-scrollbar {
            display: none;
            /* width: 3px; */
        }
        .title-container {
            margin-top: 50px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            .header-title {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: bold;
                font-size: 30px;
                line-height: 37px;
                letter-spacing: 0.02em;
                color: #191919;
            }
        }
        .header-container {
            display: flex;
            grid-column: 2 / span 1;
            width: 100%;
            height: 50px;
            margin-top: 40px;
            flex-direction: column;
            background-color: #fff;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            .header-title,
            .navigate-title {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: bold;
                font-size: 30px;
                line-height: 37px;
                letter-spacing: 0.02em;
                color: #191919;
            }
            .navigate-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                a {
                    text-decoration: none;
                    color: black;
                    cursor: pointer;
                    :hover {
                        text-decoration: underline !important;
                    }
                }
            }
            &-control {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .disable-btn {
                background-color: #b9c4d9;
            }
        }
        .table {
            background-color: white;
            position: absolute;
            z-index: 2;
            width: 100%;
            top: 130px;
            transition: 0.5s;
        }
    }
`;
const endpoint = `${hostSocket}admin/connect`;

//========================MAIN COMPONENT==========================
const MainPage = props => {
    const dispatch = useDispatch();
    const navigationData = getNavigationData();
    var startPath = "/";
    if (navigationData && navigationData.length > 0) {
        if (navigationData[0].sub && navigationData[0].sub.length > 0) {
            startPath = navigationData[0].sub[0].path;
        } else {
            startPath = navigationData[0].path;
        }
    }

    const _connect = () => {
        var ws = new WebSocket(endpoint);
        // websocket onopen event listener
        ws.onopen = evt => {
            // console.log("connected");
            // console.log(evt);
            dispatch(setSocket(ws));
        };
        // websocket onclose event listener
        ws.onclose = e => {
            console.log(e);
        };
        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );
            ws.close();
        };
        // websocket onerror event listener
        ws.onmessage = evt => {
            const message = JSON.parse(evt.data);
            console.log(message);
        };
    };
    useEffect(() => {
        _connect();
    }, []);

    const { loginReducer } = useSelector(state => ({
        loginReducer: state.loginReducer
    }));

    const permissionLogin =
        loginReducer &&
        loginReducer.checkLogin &&
        loginReducer.checkLogin.Permission;

    //======================Lifecycle Hook===========================
    //=====================Render Main Component=====================
    return (
        <Bound>
            <MenuBar />
            <div className="main-page-container">
                <Suspense fallback={<div></div>}>
                    <Switch>
                        {/* ---------- DEVICE SETTING ---------- */}
                        <Route
                            exact
                            path="/device-setting/:name"
                            render={props => {
                                switch (props.match.params.name) {
                                    case "location": {
                                        if (!permissionLogin[keys.LOCATION])
                                            return <Redirect to={startPath} />;
                                        if (
                                            permissionLogin[keys.LOCATION]
                                                .accessGetAll
                                        )
                                            return <MainLocation />;
                                        return <Redirect to={startPath} />;
                                    }
                                    case "device": {
                                        if (!permissionLogin[keys.DEVICE])
                                            return <Redirect to={startPath} />;
                                        if (
                                            permissionLogin[keys.DEVICE]
                                                .accessGetAll
                                        )
                                            return <Devices />;
                                        return <Redirect to={startPath} />;
                                    }
                                    default:
                                        return (
                                            <Redirect to="/device-setting/location" />
                                        );
                                }
                            }}
                        ></Route>
                        <Route
                            exact
                            path="/device-setting/:name/:subname/:UUID"
                            render={props => {
                                switch (props.match.params.name) {
                                    case "location":
                                        switch (props.match.params.subname) {
                                            case "detail": {
                                                if (
                                                    !permissionLogin[
                                                        keys.LOCATION
                                                    ]
                                                )
                                                    return (
                                                        <Redirect
                                                            to={startPath}
                                                        />
                                                    );
                                                if (
                                                    permissionLogin[
                                                        keys.LOCATION
                                                    ].accessView
                                                )
                                                    return <DetailLocation />;
                                                return (
                                                    <Redirect to={startPath} />
                                                );
                                            }
                                            default:
                                                return;
                                        }
                                }
                            }}
                        ></Route>
                        {/* ---------- VISITOR ---------- */}
                        <Route
                            exact
                            path="/visitor/:name"
                            render={props => {
                                if (!permissionLogin[keys.VISITOR])
                                    return <Redirect to={startPath} />;
                                switch (props.match.params.name) {
                                    case "list": {
                                        if (
                                            permissionLogin[keys.VISITOR]
                                                .accessGetAll
                                        )
                                            return <VisitorList />;
                                        return <Redirect to="/visitor/list" />;
                                    }
                                    case "new": {
                                        if (
                                            permissionLogin[keys.VISITOR]
                                                .accessNew
                                        )
                                            return <VisitorNew />;
                                        return <Redirect to="/visitor/list" />;
                                    }
                                    default:
                                        return <Redirect to="/visitor/list" />;
                                }
                            }}
                        ></Route>
                        <Route
                            exact
                            path="/visitor/:name/:ID"
                            render={props => {
                                if (!permissionLogin[keys.VISITOR])
                                    return <Redirect to={startPath} />;
                                switch (props.match.params.name) {
                                    case "detail": {
                                        if (
                                            permissionLogin[keys.VISITOR]
                                                .accessView
                                        )
                                            return <VisitorDetail />;
                                        return <Redirect to="/visitor/list" />;
                                    }
                                    case "edit": {
                                        if (
                                            permissionLogin[keys.VISITOR]
                                                .accessEditInfo ||
                                            permissionLogin[keys.VISITOR]
                                                .accessAccessLocation
                                        )
                                            return <VisitorEdit />;
                                        return <Redirect to="/visitor/list" />;
                                    }
                                    default:
                                        return <Redirect to="/visitor/list" />;
                                }
                            }}
                        ></Route>
                        {/* ---------- ACM ---------- */}
                        <Route
                            // exact
                            path="/acm/:name"
                            render={props => {
                                if (!permissionLogin[keys.ACTIVITY])
                                    return <Redirect to={startPath} />;
                                switch (props.match.params.name) {
                                    case "activities": {
                                        if (
                                            permissionLogin[keys.ACTIVITY]
                                                .accessGetAll
                                        )
                                            return <ActivityList />;
                                        return (
                                            <Redirect to="/acm/activities" />
                                        );
                                    }
                                    default:
                                        return (
                                            <Redirect to="/acm/activities" />
                                        );
                                }
                            }}
                        ></Route>
                        {/* ---------- EMPLOYEE ATTENDANCE ---------- */}
                        <Route
                            exact
                            path="/employee/:name"
                            render={props => {
                                switch (props.match.params.name) {
                                    case "list": {
                                        if (!permissionLogin[keys.EMPLOYEE])
                                            return <Redirect to={startPath} />;
                                        if (
                                            permissionLogin[keys.EMPLOYEE]
                                                .accessGetAll
                                        )
                                            // return <EmployeeList />;
                                            return <EmployeeEmptyPage />;
                                        return <Redirect to={startPath} />;
                                    }
                                    // case "new": {
                                    //     if (!permissionLogin[keys.EMPLOYEE])
                                    //         return <Redirect to={startPath} />;
                                    //     if (
                                    //         permissionLogin[keys.EMPLOYEE]
                                    //             .accessNew
                                    //     )
                                    //         return <EmployeeNew />;
                                    //     return <Redirect to="/employee/list" />;
                                    // }
                                    case "attendance": {
                                        if (!permissionLogin[keys.ATTENDANCE])
                                            return <Redirect to={startPath} />;
                                        if (
                                            permissionLogin[keys.ATTENDANCE]
                                                .accessGetAll
                                        )
                                            // return <AttendanceList />;
                                            return <AttendanceEmptyPage />;
                                        return <Redirect to={startPath} />;
                                    }
                                    default:
                                        return <Redirect to="/employee/list" />;
                                }
                            }}
                        ></Route>
                        {/* <Route
                            exact
                            path="/employee/:name/:ID"
                            render={props => {
                                if (!permissionLogin[keys.EMPLOYEE])
                                    return <Redirect to={startPath} />;
                                switch (props.match.params.name) {
                                    case "detail": {
                                        if (
                                            permissionLogin[keys.EMPLOYEE]
                                                .accessView
                                        )
                                            return <EmployeeDetail />;
                                        return <Redirect to="/employee/list" />;
                                    }
                                    case "edit": {
                                        if (
                                            permissionLogin[keys.EMPLOYEE]
                                                .accessEditInfo ||
                                            permissionLogin[keys.EMPLOYEE]
                                                .accessAccessLocation
                                        )
                                            return <EmployeeEdit />;
                                        return <Redirect to="/employee/list" />;
                                    }
                                    case "attendance": {
                                        if (props.match.params.ID === "reload")
                                            return (
                                                <Redirect to="/employee/attendance" />
                                            );
                                        return <Redirect to="/employee/list" />;
                                    }
                                    default:
                                        return <Redirect to="/employee/list" />;
                                }
                            }}
                        ></Route> */}
                        {/* ---------- ACCOUNT MANAGE ---------- */}
                        <Route
                            exact
                            path="/change-password"
                            render={() => {
                                return <ChangePassword />;
                            }}
                        ></Route>
                        <Route
                            exact
                            path="/support"
                            render={() => {
                                return <Support />;
                            }}
                        ></Route>
                        <Redirect
                            from="/device-setting/:name/reload"
                            to="/device-setting/:name"
                        />
                        <Redirect
                            from="/device-setting/location/detail/:id/reload"
                            to="/device-setting/location/detail/:id"
                        />
                        {/* <Redirect
              from="/acm/:name/reload"
              to="/acm/:name"
            /> */}
                        <Route>
                            <Redirect to={startPath} />
                        </Route>
                    </Switch>
                </Suspense>
            </div>
        </Bound>
    );
};
export default React.memo(MainPage);
MainPage.propTypes = {
    propsItem: PropTypes.string.isRequired
};
MainPage.defaultProps = {
    propsItem: "argument"
};
