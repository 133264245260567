import React, { useEffect, useRef, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RemoveAccents } from "../../helper/tools";
//img
//ic
//data
//action
//component
//styled
const Bound = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${props => props.flexDirection ? props.flexDirection : "column"};
  align-items: ${props => props.flexDirection && props.flexDirection === "row" ? "center" : "unset"};
  width: ${props => (props.widthControl ? props.widthControl : "unset")};
  margin: ${props => (props.margin ? props.margin : "unset")};
  grid-column: ${props => (props.gridColumn ? props.gridColumn : "unset")};
  .input-title {
    display: flex;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: ${props => (props.titleColor ? props.titleColor : "#000924")};
    margin: ${props =>
    props.flexDirection && props.flexDirection === "row"
      ? "0 16px 0 0"
      : "0 0 6px 0px"};
    span {
      margin: 5px 0 0 5px;
      color: #ff3b30;
      font-size: 16px;
    }
  }
  .input-textbox {
    background: ${props => props.background || "#F1F5FA"};
    border: ${props => props.borderInput ? props.borderInput : "1px solid #e8ebf1"};
    border-radius: ${props => props.borderRadius || "5"}px;
    box-sizing: border-box;
    outline: none;
    display: flex;
    height: ${props => props.heightInput || "40"}px;
    width: ${props => props.widthInput || "unset"}px;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.05px;
    color: ${props => props.colorInput || "#000924"};
    padding: 0 0 0 24px;
    overflow: hidden;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 30px white inset;
    }
    :hover {
      border: 1px solid #40a9ff  ;
    }
    :focus {
      border: 1px solid #40a9ff;
    }
    ::placeholder {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;     
      margin: 0 0 100px 0;
      color: ${props => props.colorPlaceHolder ? props.colorPlaceHolder : 'rgba(5,19,33,0.25)'};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  .text-error {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    position: absolute;
    top: 75px;
    left: 0px;
    color: #dd4b39;
    width:100%;
    /* height:16px; */
    /* white-space: nowrap;  */
    /* overflow: hidden;
    text-overflow: ellipsis;  */
  }
`;

//========================MAIN COMPONENT==========================
const InputTemplate = props => {
  //======================Props===================================
  //=====>Props Data
  const { typeInput, defaultValue, textWarning, disabled, isRemoveAccents } = props;
  //=====>Props Function
  const { onChangeInput, setTextWarning } = props;
  //======>Props Style
  const {
    title,
    titleColor,
    margin,
    placeHolder,
    flexDirection,
    background,
    widthInput,
    heightInput,
    widthControl,
    maxLength,
    gridColumn,
    colorInput,
    borderRadius,
    borderInput,
    colorPlaceHolder,
  } = props;
  //======================State===================================
  const [valueInput, setValueInput] = useState(defaultValue || "");
  //======================Redux===================================
  //=====>Redux Action
  // const dispatch = useDispatch();
  //=====>Redux Props
  // const { itemReducer } = useSelector(state => ({
  //   item: state.itemReducer
  // }));
  //======================REF====================================
  // const nameRef = React.useRef(null);
  //======================Variable================================
  // const variable = "";
  //======================Function=================================

  useEffect(() => {
    setValueInput(defaultValue)
    onChangeInput(defaultValue);
  }, [defaultValue])

  const _handleChangeInput = e => {
    e.preventDefault();
    let input = e.target;
    if (input) {
      let tmpValueInput = isRemoveAccents?
        RemoveAccents(input.value.trimStart())
        :
        input.value.trimStart()
      // if (isRemoveAccents) {
      //   setValueInput(RemoveAccents(input.value.trimStart()));
      //   onChangeInput(RemoveAccents(input.value.trimStart()));
      // }
      // else {
      //   setValueInput(input.value.trimStart())
      //   onChangeInput(input.value.trimStart());
      // };
      const lstTypeInputMustNotContainSpace = ["EMPLOYEEID", "SOCIAL_ID"];
      
      if (lstTypeInputMustNotContainSpace.includes(typeInput))
        tmpValueInput = tmpValueInput.trim();

      setValueInput(tmpValueInput);
      onChangeInput(tmpValueInput);
      _handelBlur(e)
      return;
    }
  };
  const _handelBlur = e => {
    let input = e.target;
    if (input) {
      switch (typeInput) {
        case "FULLNAME":
          if (input.value === "") {
            setTextWarning("Fullname must not be blank");
            return;
          }

          if (input.value.replace(/\s/g, "") === "") {
            setTextWarning("Fullname must contain characters");
            return;
          }

          if (input.value.length > 30) {
            setTextWarning("FullName no more than 30 characters");
            return;
          }
          setTextWarning("");
          return;
        case "EMPLOYEEID":{
          if (input.value === "") {
            setTextWarning("Employee ID must not be blank");
            return;
          }
          // if (/\s/g.test(input.value)) {
          //   setTextWarning("Employee ID cannot contain spaces");
          //   return;
          // }
          setTextWarning("");
          return;
        }
        case "SOCIAL_ID":
          if (input.value.trim() === "") {
            setTextWarning("Social ID must not be blank");
            return;
          }
          // if (/\s/g.test(input.value)) {
          //   setTextWarning("Social ID cannot contain spaces");
          //   return;
          // }
          // if (
          //   /["ẢẠÀÁÂÃÄÅĂẤẨẪẬĂẮẰẲẴẶàáảạâãäåỒỔỖỐÒÓỎỌÔÕÕÖØỏọơớờởỡợốồổỗộậăấầẩẫắằẳẵặỪỬỮỰƯưứừửữựòóôõöøỢỞỠƠỜỚỎÕỌểềếễệỄỀẾỂỆÈÉÊËẺẼẸèéẻẽẹêëðÇçĐđÌÍÎÏỈĨỊìíîïỉĩịũủụưứừửữựỨỤƯỦŨÙÚÛÜùúûüÑñŠšýỳỷỹỵŸÿýÝỶỸỴỲŽž"]/g.test(
          //     input.value.trim()
          //   )
          // ) {
          //   setTextWarning("Social ID cannot contain vietnamese");
          //   return;
          // }
          setTextWarning("");
          return;
        // case "EMPLOYEE_ID":
        //   if (input.value === "") {
        //     setTextWarning("Employee ID must not be blank");
        //     return;
        //   }
        //   if (/\s/g.test(input.value.trim())) {
        //     setTextWarning("Employee ID cannot contain spaces");
        //     return;
        //   }
        //   if (
        //     /["ẢẠÀÁÂÃÄÅĂẤẨẪẬĂẮẰẲẴẶàáảạâãäåỒỔỖỐÒÓỎỌÔÕÕÖØỏọơớờởỡợốồổỗộậăấầẩẫắằẳẵặỪỬỮỰƯưứừửữựòóôõöøỢỞỠƠỜỚỎÕỌeểềếễệỄỀẾỂỆÈÉÊËẺẼẸèéẻẽẹêëðÇçĐđÌÍÎÏỈĨỊìíîïỉĩịũủụưứừửữựỨỤƯỦŨÙÚÛÜùúûüÑñŠšýỳỷỹỵŸÿýÝỶỸỴỲŽž"]/g.test(
        //       input.value.trim()
        //     )
        //   ) {
        //     setTextWarning("Employee ID cannot contain vietnamese");
        //     return;
        //   }
        //   setTextWarning("");
        //   return;

        case "LOCATION_NAME":
          if (input.value === "") {
            setTextWarning("Location Name must not be blank");
            return;
          }
          if (input.value.length > 30) {
            setTextWarning("Location Name no more than 30 characters");
            return;
          }
          setTextWarning("");
          return;
        case "DESCRIPTION":
          if (input.value === "") {
            setTextWarning("Description must not be blank");
            return;
          }
          if (input.value.length > 30) {
            setTextWarning("Description no more than 30 characters");
            return;
          }
          setTextWarning("");
          return;
        default:
          break;
      }
    }
  };
  //======================Lifecycle Hook===========================
  //=====================Render Main Component=====================
  console.log(textWarning)
  return (
    <Bound
      margin={margin}
      titleColor={titleColor}
      background={background}
      widthControl={widthControl}
      heightInput={heightInput}
      widthInput={widthInput}
      flexDirection={flexDirection}
      gridColumn={gridColumn}
      colorInput={colorInput}
      borderRadius={borderRadius}
      borderInput={borderInput}
      colorPlaceHolder={colorPlaceHolder}
    >
      <p className="input-title">{title}</p>
      <input
        className="input-textbox"
        maxLength={maxLength}
        value={valueInput ? valueInput : ''}
        placeholder={placeHolder ? placeHolder : ""}
        name="text-input"
        style={textWarning !== "" ? { border: "1px solid #dd4b39" } : {}}
        onBlur={_handelBlur}
        onChange={_handleChangeInput}
        disabled={disabled ? disabled : false}
      />
      <p className="text-error">{textWarning}</p>
    </Bound>
  );
};
export default React.memo(InputTemplate);
InputTemplate.propTypes = {
  title: PropTypes.string,
  defaultValue: PropTypes.string,
  titleColor: PropTypes.string,
  margin: PropTypes.string,
  placeHolder: PropTypes.string,
  flexDirection: PropTypes.string,
  background: PropTypes.string,
  widthInput: PropTypes.number,
  heightInput: PropTypes.number,
  widthControl: PropTypes.string,
  maxLength: PropTypes.number,
  gridColumn: PropTypes.string,
  onChangeInput: PropTypes.func,
  borderRadius: PropTypes.number
};
