import * as types from "../types";
import * as keys from '../../../constant/key'

import { host } from "../../../host/host";
import { checkErrCode } from '../../../helper/tools'
import Axios from "axios";
import { useHistory } from "react-router";


// data
import { ADD_VISITOR_LIST_SUCCESS, PROFILE_UPDATE_SUCCESS } from "../../../data/popupData";

// action
import { openPopupNotification } from "../popupAction/popupNotificationAction";
import { getFilterAllowedLocation } from "../locationAction/locationAction"
import { storeFilterData } from '../filterAction/filterAction'
import { setFlagPrepareReload, setFlagNeedReload, setPromtNavigate } from "../systemAction/systemAction";

const SuccessCode = 0;

// dispatch image recognize

export const addImgSrcRecognize = (imgSrc) => ({
    type: types.VISITORS_ADD_IMG_RECONIZE,
    payload: {
        imgSrc
    }
})

export const addAvatarVisitor = (imgSrc) => ({
    type: types.VISITORS_ADD_AVATAR,
    payload: {
        imgSrc
    }
})

export const changeAllowedLocations = (lstLocations) => ({
    type: types.VISITORS_CHANGE_ALLOWED_LOCATIONS,
    payload: {
        lstLocations
    }
})

/**
 * 
 * @param {object} objFieldChange 
 */
export const changeGeneralInfos = (objFieldChange) => ({
    type: types.VISITORS_CHANGE_GENERAL_INFOS,
    payload: {
        ...objFieldChange
    }
})

export const setStatusChangeVisitorData = (objFieldChange) => ({
    type: types.VISITORS_STATUS_CHANGE_VISITOR_DATA,
    payload: {
        ...objFieldChange
    }
})

// reset state visitor reducer when unmount main component
export const resetItemInVisitorReducer = (fieldName) => ({
    type: types.VISITORS_RESET_STATE,
    payload: {
        fieldName
    }
})

export const addVisitor = (
    Image, VerifyImage, CompanyID, ExpirationDate, FullName, 
    Gender, BirthDay, SocialID, PermittedLocation, OrgName, IsRemoved) => async dispatch => {
//   "Image": "string",
//   "VerifyImage": "string",
//   "CompanyID": "string",
//   "OrgName": "string",
//   "ExpirationDate": "string",
//   "FullName": "string",
//   "Gender": "string",
//   "BirthDay": "string",
//   "SocialID": "string",
//   "PermittedLocation": [ "string" ]

    const api = host + "user/add-visitor";
    const dataQueryFormat = JSON.stringify({
        Image, VerifyImage, CompanyID, ExpirationDate, FullName, Gender, BirthDay, SocialID, PermittedLocation, OrgName, IsRemoved
    });
    dispatch({type:types.START_LOADING})
    try {
        const res = await Axios.post(api, dataQueryFormat);
        const { data } = res;
        const callApiSuccess = data.Code === SuccessCode ? true : false;
        console.log(res);
        console.log(callApiSuccess)
        if (callApiSuccess) {
            dispatch({
                type: types.ADD_VISTOR,
                data: data
            });
            dispatch(resetItemInVisitorReducer("dataItemExcuting"))
            dispatch(setFlagPrepareReload({visitorList: true}))
            dispatch(setPromtNavigate(false))
            useHistory().push("/visitor/list")
            dispatch(openPopupNotification(
                ADD_VISITOR_LIST_SUCCESS,
                {
                    contentFirst: `${FullName}`,        
                },
                false,
            ))
        } else {
            dispatch(setPromtNavigate(true))
            console.log(data)
            if(data.Code === 11){
                dispatch(getFilterAllowedLocation(CompanyID))
                // return;
            }
            checkErr(data.Code, dispatch, {linkPath:'/visitor/detail/'+data.UserID})
            dispatch({
                type: types.ADD_VISTOR_ERROR,
                data: data
            });
        }
    } catch (err) {
        dispatch(setPromtNavigate(true))
        dispatch({
            type: types.ADD_VISTOR_ERROR,
            err
        });
    }
};

export const getFilterVisitor = ( isFilter,
    Name, SocialID, Arrlocation, NotAllowLocation, Status, FromRegisterDate, ToRegisterDate, FromExpirationDate, ToExpirationDate, 
    PageSize, PageNumber, SortType, SortOrder) => async dispatch => {
// "Name": "string",
// "SocialID": "string",
// "Arrlocation": [ "string" ],
// "NotAllowLocation": boolean
// "Status": "Active",
// "FromRegisterDate": "string",
// "ToRegisterDate": "string",
// "FromExpirationDate": "string",
// "ToExpirationDate": "string",
// "PageSize": 0,
// "PageNumber": 0,
// "SortType": "SortName",
// "SortOrder": 0
    const api = host + "user/list-visitor-filter";
    let dataQuery={Name, SocialID, Arrlocation, NotAllowLocation, Status, FromRegisterDate, ToRegisterDate, FromExpirationDate, ToExpirationDate, 
        PageSize, PageNumber, SortType, SortOrder}
    const dataQueryFormat = JSON.stringify(dataQuery);
    dispatch({type:types.START_LOADING})
    try {
        const res = await Axios.post(api, dataQueryFormat);
        const { data } = res;
        const callApiSuccess = data.Code === SuccessCode ? true : false;
        console.log(res);
        if (callApiSuccess) {
            dispatch(storeFilterData(keys.VISITOR, {
                ...dataQuery
            }))
            dispatch({
                type: types.GET_FILTER_VISTOR,
                data: data,
                isFilter
            });
        } else {
            checkErr(data.Code, dispatch)
            dispatch({
                type: types.GET_FILTER_VISTOR_ERROR,
                data: data
            });
        }
    } catch (err) {
        dispatch({
            type: types.GET_FILTER_VISTOR_ERROR,
            err
        });
    }
};

export const getVisitor = (UserID, CompanyID) => async dispatch => {
    // "UserID": "string",
    // "CompanyID": "string"
    const api = host + "user/get";
    const dataQueryFormat = JSON.stringify({
        UserID, CompanyID
    });
    dispatch({type:types.START_LOADING})
    try {
        const res = await Axios.post(api, dataQueryFormat);
        const { data } = res;
        const callApiSuccess = data.Code === SuccessCode ? true : false;
        console.log(res);
        if (callApiSuccess) {
            dispatch({
                type: types.GET_VISTOR,
                data: data
            });
        } else {
            checkErr(data.Code, dispatch)
            dispatch({
                type: types.GET_VISTOR_ERROR,
                data: data
            });
        }
    } catch (err) {
        dispatch({
            type: types.GET_VISTOR_ERROR,
            err
        });
    }
};

export const refreshVisitor = () => async dispath => {
    dispath({type:types.REFRESH_VISTOR_DETAIL})
}

export const updateVisitor = (UserID, CompanyID, OrgName, ExpirationDate, FullName, Gender, SocialID, BirthDay, Image, VerifyImage, PermittedLocation) => async dispatch => {
//   "UserID": "string",
//   "CompanyID": "string",
//   "OrgName": "string",
//   "ExpirationDate": "string",
//   "FullName": "string",
//   "Gender": "string",
//   "SocialID": "string",
//   "BirthDay": "string"
//   "Image": "string",
//   "VerifyImage": "string",
//   "PermittedLocation": [
//     "string"
//   ]
    const api = host + "user/update-visitor";
    const dataQueryFormat = JSON.stringify({
        UserID, 
        CompanyID, 
        OrgName,
        ExpirationDate, 
        FullName, 
        Gender,
        SocialID, 
        BirthDay,
        Image, 
        VerifyImage, 
        PermittedLocation
    });
    dispatch({type:types.START_LOADING})
    try {
        const res = await Axios.post(api, dataQueryFormat);
        const { data } = res;
        const callApiSuccess = data.Code === SuccessCode ? true : false;
        console.log(res);
        
        if (callApiSuccess) {
            dispatch({
                type: types.UPDATE_VISTOR,
                data: data
            });
            dispatch(openPopupNotification(
                PROFILE_UPDATE_SUCCESS,
                {
                  contentProps: FullName,        
                },
                false,
            ))
            dispatch(setFlagNeedReload({ visitorList: true }))
        } else {
            checkErr(data.Code, dispatch, {linkPath:'/visitor/detail/'+data.UserID})
            dispatch({
                type: types.UPDATE_VISTOR_ERROR,
                data: data
            });
        }
    } catch (err) {
        dispatch({
            type: types.UPDATE_VISTOR_ERROR,
            err
        });
    }
};


export const setActiveVisitor = (UserID, CompanyID, IsRemoved) => async dispatch => {
    //   "UserID": "string",
    //   "CompanyID": "string",
    //   "IsRemoved": "boolen",
    const api = host + "user/set-remove";
    const dataQueryFormat = JSON.stringify({
        UserID, CompanyID, IsRemoved
    });
    dispatch({type:types.START_LOADING})
    try {
        const res = await Axios.post(api, dataQueryFormat);
        const { data } = res;
        const callApiSuccess = data.Code === SuccessCode ? true : false;
        // console.log(res);
        if (callApiSuccess) {
            dispatch({
                type: types.SET_ACTIVE_VISITOR,
                data: data
            });
        } else {
            checkErr(data.Code, dispatch)
            dispatch({
                type: types.SET_ACTIVE_VISITOR_ERROR,
                data: data
            });
        }
    } catch (err) {
        dispatch({
            type: types.SET_ACTIVE_VISITOR_ERROR,
            err
        });
    }
};

const checkErr = (code, dispatch, data) => {
    let objErr = checkErrCode(keys.VISITOR, code)
    console.log(code, data)
    dispatch(openPopupNotification(objErr.popupType, data, false))
}