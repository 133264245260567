import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
/// images
import ic_warning_remove from "../../../images/ic_warning_remove.svg";
import ic_close_remove_popup from "../../../images/ic_close_remove_popup.svg";
/// data
import { UNLINK_LOCATION_FROM_DEVICE, UNLINK_DEVICE_FROM_LOCATION } from "../../../data/popupData";

/// components
import { closePopup } from "../../../redux/actions/popupAction/popupContainerAction";
//action
import { deviceUnlinkLocation } from "../../../redux/actions/deviceAction/deviceAction";
const Bound = styled.div`
  padding: 23px 38px;
  width: 409px;
  height: 324px;
  box-sizing: border-box;
  border-radius: 16px;
  background: #ffffff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  background: #fafafa;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  /* animate */
  animation-name: isShowPopup;
  transition-property: bottom;
  transition-duration: 500ms;
  display: relative;
  @keyframes isShowPopup {
    from {
      top: -100px;
    }
    to {
      top: 45px;
    }
  }
  .ic-close-remove-popup {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 100%;
  /* margin-top: 162px; */
  text-align: center;
  .ic-warning-remove {
  }
  .confirm-remove-title {
    margin-top: 12px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #051321;
    .block-location-remove {

      span:nth-child(2) {
        display: inline-block;
        color: #33adff;
        margin: 0 5px;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .target-remove > span {
        font-weight: bold;
      }
      .block-text {
        display: flex;
        justify-content: center;
        align-items: center;
        .device-name,.location-name {
          max-width: 150px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .confirm-remove-content {
    padding: 0 35px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #000924;
  }
  .block-btn {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    .remove-btn,
    .cancel-btn {
      width: 160px;
      height: 50px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.03em;
      cursor: pointer;
    }
    .remove-btn {
      background: #ea3939;
      color: #ffffff;
      margin-right: 12px;
    }
    .cancel-btn {
      color: #33adff;
      background: #ffffff;
      border: 0.572641px solid #33adff;
    }
  }
`;

const infosOfPopup = {
  [UNLINK_LOCATION_FROM_DEVICE]: {
    targetRemove: "location",
    note: "This location will be unliked immediatly. You can't undo this action.",
    btnSubmitTitle: "REMOVE"
  },
  [UNLINK_DEVICE_FROM_LOCATION]: {
    targetRemove: "device",
    note: "This device will be unlinked immediatly. You can't undo this action.",
    btnSubmitTitle: "UNLINK"
  },
  [String(undefined)]: {
    targetRemove: "-",
    note: "-",
    btnSubmitTitle: "-"
  } 
}

function PopupUnlinkLocation(props) {
  const dispatch = useDispatch();
  const { data, TypePopup } = useSelector(state => ({
    TypePopup: state.popupContainerReducer.typePopup,
    data: state.popupContainerReducer.data
  }));

  const [detailPopup, setDetailPopup] = useState(infosOfPopup[data.typeDetail]);
  const [targetRemove, setTargetRemove] = useState('-');
  const [fromTarget, setFromTarget] = useState('-')

  useEffect(() => {
    setDetailPopup(infosOfPopup[data.typeDetail])
    switch (data.typeDetail){
      case UNLINK_DEVICE_FROM_LOCATION:
        setTargetRemove(data.Name)
        setFromTarget(data.InfoLocation.Name)
        break;
      case UNLINK_LOCATION_FROM_DEVICE:
        setTargetRemove(data.InfoLocation.Name)
        setFromTarget(data.Name)
        break;
      default:
        break;
    }
  }, [data.typeDetail])

  const _onClosePopup = () => {
    dispatch(closePopup());
  };

  const _onConfirmRemove = () => {
    dispatch(deviceUnlinkLocation(data.ID));
    dispatch(closePopup());
  };
  return (
    <Bound>
      {/* <img
        className="ic-close-remove-popup"
        src={ic_close_remove_popup}
        onClick={_onClosePopup}
        alt="ic_close"
      /> */}

      <Content>
        <img
          className="ic-warning-remove"
          src={ic_warning_remove}
          alt="ic_warning"
        />
        <div className="confirm-remove-title">
          <span>Are you sure you want to unlink </span>
          <div className="block-location-remove">
            <div className="block-text target-remove">
              <span>{detailPopup.targetRemove}</span>
              <span className="location-name">{targetRemove}</span>
            </div>
            <div className="block-text">
              <span>from</span>
              <span className="device-name" style={{ color: "#051321" }}>{fromTarget}</span>
              <span>?</span>
            </div>
          </div>
        </div>
        <p className="confirm-remove-content">
          {detailPopup.note}
        </p>
        <div className="block-btn">
          <div className="remove-btn" onClick={_onConfirmRemove}>
            {detailPopup.btnSubmitTitle}
          </div>
          <div className="cancel-btn" onClick={_onClosePopup}>
            CANCEL
          </div>
        </div>
      </Content>
    </Bound>
  );
}

PopupUnlinkLocation.propTypes = {
  data: PropTypes.object
};

export default PopupUnlinkLocation;
